import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import store from './store'
import './assets/scss/main.scss'
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import PrimeVue from 'primevue/config';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
const app = createApp(App)

app.component('TabView', TabView);
app.component('TabPanel', TabPanel);

app.use(store).use(router)

app.use(PrimeVue);
router.isReady().then(() => app.mount('#app'))
