import { ref } from 'vue'

export default {
  name: 'ErocsolNavN2',

  setup() {
    const nav = ref()
    const toggleBurger = () => {
      nav.value.classList.toggle('nav__block--active')
    }

    const toggleActive = () => {
      if (nav.value.classList.contains('nav__block--active')) {
        nav.value.classList.remove('nav__block--active')
      }
    }

    return {
      toggleBurger,
      toggleActive,
      nav,
    }
  },
}
