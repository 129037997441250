import { createRouter, createMemoryHistory } from 'vue-router'
import Services from '@/views/Services/Services'
import library from '@/views/TheLibrary/TheLibrary'
import AboutUs from '@/views/AboutUs/AboutUs'
import ContactUs from '@/views/ContactUs/ContactUs'
import ComingSoon from '@/views/ComingSoon/ComingSoon'

import EffectiveTraining from '@/views/TheLibrary/LibraryBlogs/EffectiveTraining'
import LearningEffect from '@/views/TheLibrary/LibraryBlogs/LearningEffect'
import RemoteSelling from '@/views/TheLibrary/LibraryBlogs/RemoteSelling'
import TheExperience from '@/views/TheLibrary/LibraryBlogs/TheExperience'
import TheLeadership from '@/views/TheLibrary/LibraryBlogs/TheLeadership'
import TheReskilling from '@/views/TheLibrary/LibraryBlogs/TheReskilling'

import MainNav from '@/components/MainNav/MainNav'
import MainFooter from '@/components/MainFooter/MainFooter'

const routes = [
  {
    path: '/',
    name: 'services',
    components: {
      default: Services,
      nav: MainNav,
      footer: MainFooter,
    },
  },
  {
    path: '/library',
    name: 'library',
    components: {
      default: library,
      nav: MainNav,
      footer: MainFooter,
    },
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    components: {
      default: AboutUs,
      nav: MainNav,
      footer: MainFooter,
    },
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    components: {
      default: ContactUs,
      nav: MainNav,
      footer: MainFooter,
    },
  },
  {
    path: '/effectiveTraining',
    name: 'effectiveTraining',
    components: {
      default: EffectiveTraining,
      nav: MainNav,
      footer: MainFooter,
    },
  },
  {
    path: '/learningEffect',
    name: 'learningEffect',
    components: {
      default: LearningEffect,
      nav: MainNav,
      footer: MainFooter,
    },
  },
  {
    path: '/remoteSelling',
    name: 'remoteSelling',
    components: {
      default: RemoteSelling,
      nav: MainNav,
      footer: MainFooter,
    },
  },
  {
    path: '/experience',
    name: 'experience',
    components: {
      default: TheExperience,
      nav: MainNav,
      footer: MainFooter,
    },
  },
  {
    path: '/leadership',
    name: 'leadership',
    components: {
      default: TheLeadership,
      nav: MainNav,
      footer: MainFooter,
    },
  },
  {
    path: '/reskilling',
    name: 'reskilling',
    components: {
      default: TheReskilling,
      nav: MainNav,
      footer: MainFooter,
    },
  },
  {
    path: '/ComingSoon',
    name: 'ComingSoon',
    component: ComingSoon,
  },
]

const router = createRouter({
  mode: 'history',
  history: createMemoryHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

export default router
