import {ref} from 'vue'
export default {
  setup() {
    const showModal = ref(false)
    const submit = () => {
      showModal.value = true
    }
    return {
      submit,
      showModal
    }
  },
}
